<template>
  <!--结算已收款-->
  <div style="padding: 20px;">
    <div v-if="showDetails === false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="客户名称:">
                    <el-input
                      clearable
                      v-model="where.channel_name"
                      placeholder="请输入客户名称"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="对账单号:">
                    <el-input
                      clearable
                      v-model="where.odd_number"
                      placeholder="请输入对账单号"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                    unlink-panels
                     v-model="where.time"
                     value-format="yyyy-MM-dd"
                     type="daterange"
                     range-separator="至"
                     start-placeholder="开始日期"
                     end-placeholder="结束日期">
                    </el-date-picker>
                    <!-- <DatePicker v-model="where.time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/> -->
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label=" ">
                    <div class="ele-form-actions">
                      <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                      </el-button>
                      <el-button @click="empty">清空</el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <!--<el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)">-->
                <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                <!--  <i class="el-icon-tianjia" />-->
                <!--  <span style="vertical-align: middle">发起对账</span>-->
                <!--</el-button>-->
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 30px;">结算金额：</span>
                <span style="font-size: 18px;font-weight: 700;color: #fea837;">{{tongji.receivable}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">扣款金额：</span>
                <span style="font-size: 18px;font-weight: 700;color: #FF2525;">{{tongji.entirety_repair}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">订单数量：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4E4E4E;">{{tongji.order_count}}</span>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <template v-slot:period= "{ scope }">
                <div v-for="(item) in scope.row.period">
                  {{item}}
                </div>
              </template>

              <template v-slot:invoice= "{ scope }">
                <span v-if="scope.row.invoice != ''" style="color: #00a7ff;cursor: pointer;" @click="open(scope.row.invoice)">点击查看</span>
              </template>
              <template v-slot:payment_proof= "{ scope }">
                <span v-if="scope.row.payment_proof != ''" style="color: #00a7ff;cursor: pointer;" @click="open(scope.row.payment_proof)">点击查看</span>
              </template>


              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}finance:settlementReceived:info`]">详情</el-link>
                <!--<el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">-->
                <!--  <el-link slot="reference" :underline="false" style="margin-left: 10px;">删除</el-link>-->
                <!--</el-popconfirm>-->
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--详情组件-->
    <Details v-if="showDetails === true" @showDetails="showdetails" :data="Detailscurrent" :dict="dict"/>

    <el-dialog
      title="查看"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="guanbi">
      <div style="cursor: pointer;">
        <img :src="img" alt="" style="width: 100%;">
      </div>
    </el-dialog>

  </div>
</template>

<script>
//引入详情组件
import Details from '../toInitiateReconciliation/details.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {getdelay_proceeds,reconciliation_statistics} from "@/api/finance";
import Vue from "vue";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    Details
  },
  data(){
    return{
      //统计
      tongji:{},
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "对账单号",
          // type: "index",
          // width: "70"
          prop: "odd_number",
          isShow: true
        },
        {
          label: "对账周期",
          prop: "period",
          slot: 'period',
          isShow: true
        },
        {
          label: "客户名称",
          prop: "channel_name",
          isShow: true
        },
        {
          label: "对账状态",
          prop: "status_name",
          isShow: true
        },
        {
          label: "订单总量",
          prop: "order_count",
          isShow: true
        },
        {
          label: "合同价",
          prop: "contract_price",
          isShow: true
        },
        {
          label: "整体补扣款",
          prop: "entirety_repair",
          isShow: true
        },
        {
          label: "整体补扣款原因",
          prop: "entirety_repair_reason",
          isShow: true
        },
        {
          label: "应收款",
          prop: "receivable",
          isShow: true
        },
        {
          label: "已收款",
          prop: "pay_status_name",
          isShow: true
        },
        {
          label: "发票",
          prop: "invoice",
          slot:'invoice',
          isShow: true
        },
        {
          label: "付款凭证",
          prop: "payment_proof",
          slot: "payment_proof",
          isShow: true
        },
        {
          label: "操作",
          width: "100",
          slot: "operationSlot",
          isShow: true
        },
      ],
      //查看
      dialogVisible:false,
      img:'',

      // 列表设置弹窗
      ListSetupShow:false,

      //详情组件
      Detailscurrent:null,
      showDetails:false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '财务字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getTongji();
    this.getList();
  },

  methods:{
    //统计
    getTongji(){
      let id = 5;
      reconciliation_statistics(id).then(res => {
        this.tongji = res.data;
      })
    },
    // 获取列表
    getList(){
      getdelay_proceeds(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择日期
    datechange(e){
      this.where.time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    //点击查看
    open(value){
      this.dialogVisible = true;
      this.img = value;
    },
    //关闭
    guanbi(){
      this.dialogVisible = false;
    },


    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //打开详情
    openEdit(row){
      this.Detailscurrent = row;
      this.showDetails = true;
    },
    //接收详情子组件数据
    showdetails(value){
      this.showDetails = value;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
